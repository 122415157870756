let notify_messages
const notify_messages_en = {
  domainInvalid: 'The domain address is invalid.',
  mailInvalid: 'The email address is invalid.',
  highDemand: {
    title: 'You can only create one live demo for 24 hours',
    text: 'Due to high demand, a user can only create one live demo per each day.'
  },
  alreadyRegistered: 'User is already registered.'
}

const notify_messages_tr = {
  domainInvalid: 'Domain adresi geçerli değil.',
  mailInvalid: 'E-posta adresi geçerli değil.',
  highDemand: {
    title: '24 saat içinde sadece bir canlı demo oluşturabilirsiniz.',
    text: 'Yüksek talep nedeniyle, bir kullanıcı her gün sadece bir canlı demo oluşturabilir.'
  },
  alreadyRegistered: 'Bu kullanıcı halihazırda kayıtlı.'
}

if (window.location.pathname.split('/')[1] === 'tr') {
  notify_messages = notify_messages_tr
} else {
  notify_messages = notify_messages_en
}

export default notify_messages
