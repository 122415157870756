import { hasClass, isMobile, resizeIndicator } from './helpers'

export default function () {
  const rankMobileButtons = document.querySelector('.rank__mobile-buttons')
  const rankButtonsWrapper = document.querySelector('.rank__buttons')
  const rankIndicator = document.querySelector('.rank__indicator')

  const node = {
    buttons: '.rank__button',
    active: 'rank__button--active'
  }

  if (isMobile()) {
    node.buttons = '.rank__m-button'
    node.active = 'rank__m-button--active'
    rankMobileButtons.style.display = 'flex'
    rankButtonsWrapper.style.display = 'none'
  } else {
    rankMobileButtons.style.display = 'none'
    rankButtonsWrapper.removeAttribute('style')
  }

  const rankButtons = document.querySelectorAll(node.buttons)
  const rankContents = document.querySelectorAll('.rank__content')

  let rankID = 'analyzer'
  const rankSection = document.querySelector('.rank')

  const rankObserver = new IntersectionObserver(
    (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          document.querySelector('.rank__content--active video').play()
        } else {
          document.querySelector('.rank__content--active video').pause()
          document.querySelector('.rank__content--active video').currentTime = 0
        }
      })
    },
    {
      threshold: 0
    }
  )

  rankObserver.observe(rankSection)

  rankButtons.forEach((button) => {
    button.addEventListener('click', (e) => {
      !hasClass(e.currentTarget, node.active) && rankContentTab(e.currentTarget, true)
    })
    hasClass(button, node.active) && rankContentTab(button, false)
  })

  function rankContentTab(el, clicked) {
    rankButtons.forEach((button) => {
      button.classList.remove(node.active)
    })
    rankContents.forEach((content) => {
      content.classList.remove('rank__content--active')
      content.querySelector('video').pause()
      content.querySelector('video').currentTime = 0
    })
    rankID = el.getAttribute('data-rank-id')
    const tab = document.querySelector(`[data-rank="${rankID}"]`)
    tab.classList.add('rank__content--active')
    tab.querySelector('video').play()
    el.classList.add(node.active)
    rankIndicator.style.transform = `translate(0, ${el.offsetTop}px)`
    resizeIndicator(rankIndicator, '.rank__button.rank__button--active')
  }

  if (!isMobile()) {
    /* gsap.from('.rank__title', {
      autoAlpha: 0,
      y: 30,
      rotation: 0.01,
      scrollTrigger: {
        trigger: '.rank__title',
        start: 'top bottom'
      }
    })

    gsap.from('.rank .label', {
      autoAlpha: 0,
      y: 30,
      rotation: 0.01,
      scrollTrigger: {
        trigger: '.rank .label',
        start: 'top bottom'
      }
    })

    gsap.from('.rank__desc', {
      autoAlpha: 0,
      y: -40,
      rotation: 0.01,
      scrollTrigger: {
        trigger: '.rank__desc',
        start: 'top bottom'
      }
    })

    gsap.from('.rank__contents', {
      autoAlpha: 0,
      x: -100,
      rotation: 0.01,
      scrollTrigger: {
        trigger: '.rank__contents',
        start: 'top bottom'
      }
    })

    gsap.from('.rank__buttons', {
      autoAlpha: 0,
      x: 100,
      rotation: 0.01,
      scrollTrigger: {
        trigger: '.rank__buttons',
        start: 'top bottom'
      }
    })

    gsap.from('.rank__indicator', {
      width: 0,
      rotation: 0.01,
      scrollTrigger: {
        trigger: '.rank__indicator',
        start: 'top bottom'
      }
    }) */
  }
}
