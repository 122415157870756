import { isMobile, hasClass } from './helpers'

export default function () {
  if (!isMobile()) {
    // BLOBS
    const smartBlobs = document.querySelectorAll('.smart__cloud > svg')
    const smartSection = document.querySelector('.smart')
    const smartItemsAnimated = document.querySelectorAll('.smart__cloud-item--animated')

    smartItemsAnimated.forEach((sp) => {
      const video = sp.querySelector('.smart__lottie video')
      sp.addEventListener('mouseenter', () => {
        video.play()
      })

      sp.addEventListener('mouseleave', () => {
        video.pause()
        video.currentTime = 0
      })
    })

    // BLOBS
    smartBlobs.forEach((sb) => {
      const smBlobWarp = new Warp(sb)
      let sbRAF

      smBlobWarp.extrapolate(40)
      smBlobWarp.transform(([x, y]) => [x, y, y])

      let smBlobOffset = 0
      function smBlobAnimate() {
        smBlobWarp.transform(([x, y, oy]) => [x, oy + 8 * Math.sin(x / 50 + smBlobOffset), oy])
        smBlobOffset += 0.05
        sbRAF = requestAnimationFrame(smBlobAnimate)
      }

      const smObserver = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              sbRAF = requestAnimationFrame(smBlobAnimate)
            } else {
              cancelAnimationFrame(sbRAF)
            }
          })
        },
        {
          threshold: 0
        }
      )

      smObserver.observe(smartSection)
    })

    // SCROLLTRIGGER
    /* gsap.from('.smart__cloud-item', {
      autoAlpha: 0,
      stagger: 1,
      rotation: 0.01,
      duration: 6,
      y: -40,
      scrollTrigger: {
        trigger: '.smart__clouds',
        start: 'top bottom'
      }
    })

    gsap.from('.smart__title', {
      autoAlpha: 0,
      y: 30,
      rotation: 0.01,
      scrollTrigger: {
        trigger: '.smart__title',
        start: 'top bottom'
      }
    })

    gsap.from('.smart__desc', {
      autoAlpha: 0,
      y: -40,
      rotation: 0.01,
      scrollTrigger: {
        trigger: '.smart__desc',
        start: 'top bottom'
      }
    })

    gsap.from('.smart .button', {
      autoAlpha: 0,
      y: 40,
      rotation: 0.01,
      scrollTrigger: {
        trigger: '.smart .button',
        start: 'top bottom'
      }
    }) */
  } else {
    const items = document.querySelectorAll('.smart__cloud-item')
    const closeButtons = document.querySelectorAll('.smart__cloud-close')

    closeButtons.forEach((button) => {
      button.addEventListener('click', (e) => {
        e.preventDefault()
        e.stopPropagation()
        document.body.removeAttribute('style')
        const activeItem = document.querySelector('.smart__cloud-item--active')
        if (activeItem) activeItem.classList.remove('smart__cloud-item--active')
      })
    })

    items.forEach((item) => {
      item.addEventListener('click', (e) => {
        const { currentTarget } = e
        if (!hasClass(currentTarget, 'smart__cloud-item--active')) {
          document.body.style.overflow = 'hidden'
          const activeItem = document.querySelector('.smart__cloud-item--active')
          if (activeItem) activeItem.classList.remove('smart__cloud-item--active')
          currentTarget.classList.add('smart__cloud-item--active')
        }
      })
    })
  }
}
