import { isMobile, numFormatter } from './helpers'
import countries from '../data/countries.js'
import { getDomain, parse } from 'tldts'
import notify_messages from './notify_messages'

const notify_defaults = {
  type: 2,
  autoclose: true,
  autotimeout: 3000
}

export default function () {
  const heroBlob = document.querySelector('.hero__blob')
  const heroSection = document.querySelector('.hero')
  const heroBlobWarp = new Warp(heroBlob)
  let heroRAF

  heroBlobWarp.extrapolate(40)
  heroBlobWarp.transform(([x, y]) => [x, y, y])

  let heroBlobOffset = 0
  function heroBlobAnimate() {
    heroBlobWarp.transform(([x, y, oy]) => [x, oy + 7 * Math.sin(x / 16 + heroBlobOffset), oy])
    heroBlobOffset += 0.05
    heroRAF = requestAnimationFrame(heroBlobAnimate)
  }

  if (!isMobile()) {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            heroRAF = requestAnimationFrame(heroBlobAnimate)
          } else {
            cancelAnimationFrame(heroRAF)
          }
        })
      },
      {
        threshold: 0
      }
    )
    observer.observe(heroSection)
  }

  const getDemo = document.querySelector('.get-demo')
  const handler = document.querySelector('.get-demo__handler')
  const getDemoInput = document.querySelector('#demo-website')
  const getDemoResults = document.querySelector('.get-demo__results')
  const getDemoDetails = document.querySelector('.get-demo__details')
  const demoForm = document.querySelector('.get-demo__form')
  const getDemoButton = document.querySelector('.get-demo__button')
  const getDemoStatus = document.querySelector('.get-demo__status')
  const demoCta = document.querySelector('.hero__mobile-cta')
  const demoDesktopCta = document.querySelectorAll('.hero__desktop-cta')
  const startFreeTrialBtn = document.querySelectorAll('.start-free-trial-btn')
  const demoHeaderCta = document.querySelector('.hero__header-cta')
  const getDemoBlockMail = document.querySelector('.get-demo__block--mail')
  const getDemoFormClose = document.querySelector('.get-demo__form-close')
  const getDemoStatusLiveCta = document.querySelector('.get-demo__status-live')
  const heroInputDomains = document.querySelectorAll('.hero__input-domain')
  const heroResults = document.querySelectorAll('.get-demo__results--hero')
  const getDemoDetailCountry = document.querySelector('.get-demo__detail-value--country')
  const getDemoDetailKeywords = document.querySelector('.get-demo__detail-value--keywords')
  const getDemoMailInput = document.getElementById('demo-mail')
  const getDemoStatusCloseBtn = document.querySelector('.get-demo__status-close')
  let isAPIError = false

  let selectedDomain = null
  let marketShareResultData = null
  let createUserData = null
  let selectFirstDomain = false
  let noClearbitData = false

  let heroCtaButtonFocusCheck = false

  // move via arrow keys
  let hoveredViaArrowKeys = -1
  let isFirstKey = 1
  let hovered = {
    src: null,
    name: null,
    domain: null
  }
  let isArrowUpOrDownPressed = false

  window.addEventListener('load', () => {
    getDemoStatusLiveCta.setAttribute('href', '#demo')
    heroInputDomains[0].focus()
  })

  getDemoStatusLiveCta.addEventListener('click', () => {
    getDemo.classList.remove('get-demo--active')
  })

  const wrapperClicked = (name, logo, domain) => {
    selectedDomain = domain
    !getDemo.classList.contains('get-demo--active') ? getDemo.classList.add('get-demo--active') : ''
    getDemoInput.value = `${name} - ${domain}`
    getDemoInput.style.backgroundImage = `url("${logo}")`
    getDemoInput.style.paddingLeft = '58px'
    getDemoInput.style.fontWeight = '500'
    getDemoDetails.classList.add('get-demo__details--active')
    getDemoResults.innerHTML = ''
    heroResults.forEach((p) => (p.innerHTML = ''))
    heroInputDomains.forEach((p) => (p.value = ''))

    getDemoBlockMail.style.display = 'block'
    updateDomainResultData(domain)
    getDemoMailInput.focus()

    hoveredViaArrowKeys = -1
    isFirstKey = 1
    isArrowUpOrDownPressed = false
    hovered.name = null
    hovered.src = null
    hovered.domain = null
  }

  const resultItem = (name, logo, domain) => {
    const wrapper = document.createElement('button')
    wrapper.classList.add('get-demo__result')
    wrapper.innerHTML = `
      <img src="${logo}" width="32px" height="32px" alt="result logo" />
      <span>${name}</span>
      <i>${domain}</i>
    `

    wrapper.addEventListener('click', () => {
      wrapperClicked(name, logo, domain)
    })

    return wrapper
  }

  function demoModalClose() {
    getDemo.classList.remove('get-demo--active')
  }

  getDemoFormClose.addEventListener('click', demoModalClose)
  getDemoStatusCloseBtn.addEventListener('click', demoModalClose)
  document.addEventListener('keyup', (e) => {
    e = e || window.event
    if (e.key === 'Escape') demoModalClose()
  })

  const isEmail = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i

  function demoPOST() {
    if (!parse(selectedDomain).isIcann || (!marketShareResultData && !parse(getDemoInput.value).isIcann)) {
      new Notify({
        ...notify_defaults,
        status: 'warning',
        title: notify_messages.domainInvalid
      })
    } else if (!getDemoMailInput.value.match(isEmail)) {
      new Notify({
        ...notify_defaults,
        status: 'warning',
        title: notify_messages.mailInvalid
      })
    }

    if (
      getDemoMailInput.value.trim().length &&
      getDemoMailInput.value.match(isEmail) &&
      createUserData &&
      createUserData.language &&
      marketShareResultData &&
      marketShareResultData.country
    ) {
      fetch(`https://api-new.seo.do/auth0_create_user`, {
        method: 'POST',
        body: JSON.stringify({
          email: getDemoMailInput.value,
          domain: selectedDomain,
          language: createUserData.language,
          country: marketShareResultData.country
        })
      })
        .then((response) => {
          if (response.status !== 200) {
            isAPIError = true
            if (response.status === 429) {
              new Notify({
                ...notify_defaults,
                status: 'error',
                title: notify_messages.highDemand.title,
                text: notify_messages.highDemand.text
              })
            }
            if (response.status === 400) {
              new Notify({
                ...notify_defaults,
                status: 'error',
                title: notify_messages.alreadyRegistered
              })
            }
          } else {
            isAPIError = false
            return response.json()
          }
        })
        .then((data) => {
          if (!isAPIError) {
            demoForm.style.display = 'none'
            getDemoStatus.classList.add('get-demo__status--active')
          }
        })
        .catch((err) => {
          console.warn('Something went wrong.', err)
        })
    }
  }

  getDemoButton.addEventListener('click', demoPOST)
  getDemoMailInput.addEventListener('keyup', (e) => {
    if (e.key === 'Enter') demoPOST()
  })

  getDemoInput.addEventListener('focus', (e) => {
    e.target.value = ''
    getDemoInput.removeAttribute('style')
    getDemoBlockMail.style.display = 'none'
    getDemoDetails.removeAttribute('style')
    getDemoDetails.classList.remove('get-demo__details--active')
    marketShareResultData = null
    selectedDomain = null
  })

  function preventJumpingBack(e) {
    if (e.key === 'ArrowUp') e.preventDefault()
  }

  function moveViaKeys(e) {
    const NEXT_ALL = e.target.nextElementSibling.querySelectorAll('.get-demo__result')
    const RESULTS_LENGTH = NEXT_ALL.length

    if (e.key === 'ArrowUp') {
      isFirstKey ? (hoveredViaArrowKeys = RESULTS_LENGTH - 1) : (hoveredViaArrowKeys -= 1)
      hoveredViaArrowKeys < 0 ? (hoveredViaArrowKeys = RESULTS_LENGTH - 1) : ''
    } else if (e.key === 'ArrowDown') {
      isFirstKey ? (hoveredViaArrowKeys = 0) : (hoveredViaArrowKeys += 1)
      hoveredViaArrowKeys >= RESULTS_LENGTH ? (hoveredViaArrowKeys = 0) : ''
    }

    if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
      isArrowUpOrDownPressed = true

      NEXT_ALL.forEach((p, i) => {
        if (i !== hoveredViaArrowKeys) NEXT_ALL[i].classList.remove('hover')
      })
      NEXT_ALL[hoveredViaArrowKeys].classList.add('hover')

      if (hoveredViaArrowKeys !== 0) {
        hovered.src = NEXT_ALL[hoveredViaArrowKeys].querySelector('img').getAttribute('src')
        hovered.name = NEXT_ALL[hoveredViaArrowKeys].querySelector('span').textContent
        hovered.domain = NEXT_ALL[hoveredViaArrowKeys].querySelector('i').textContent
      } else {
        hovered.src = `https://twaigulgqc.execute-api.eu-central-1.amazonaws.com/production/logodo?domain=${
          NEXT_ALL[0].querySelector('i').textContent
        }&size=32&type=logo`
        hovered.name = NEXT_ALL[0].querySelector('span').textContent
        hovered.domain = NEXT_ALL[0].querySelector('i').textContent
      }

      isFirstKey = 0
    } else {
      hoveredViaArrowKeys = -1
      isFirstKey = 1
    }

    const val = e.target.value.trim()

    if (e.key === 'Enter' && hovered.name !== null && parse(hovered.domain).isIcann) {
      wrapperClicked(hovered.name, hovered.src, hovered.domain)
    } else if (e.key === 'Enter' && parse(val).isIcann) {
      wrapperClicked(val, `https://twaigulgqc.execute-api.eu-central-1.amazonaws.com/production/logodo?domain=${val}&size=32&type=logo`, val)
    }
  }

  heroInputDomains.forEach((p) => {
    p.addEventListener('keydown', preventJumpingBack)
    p.addEventListener('input', getLeanDomain)
  })

  getDemoInput.addEventListener('input', getLeanDomain)

  // get lean domain: https://seo.do/  ->  seo.do
  function getLeanDomain(e) {
    const isURL = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi
    const regex = new RegExp(isURL)
    if (e.target.value.match(regex)) {
      e.target.value = getDomain(e.target.value)
    }
  }

  let controller = new AbortController()
  let signal = controller.signal

  function getDemoInputResults(e) {
    controller.abort()
    controller = new AbortController()
    signal = controller.signal
    demoForm.style.display = 'block'
    getDemoStatus.classList.remove('get-demo__status--active')
    getDemoMailInput.value = ''

    const value = e.target.value.trim()
    const resultContainer = e.target.nextElementSibling
    if (value.length !== 0) {
      fetch(`https://autocomplete.clearbit.com/v1/companies/suggest?query=${value}`, {
        method: 'get',
        signal: signal
      })
        .then((response) => {
          return response.json()
        })
        .then((data) => {
          data.sort((a, b) => a.domain.localeCompare(b.domain))
          if (data.length === 0) {
            noClearbitData = true
            resultContainer.innerHTML = ''
            getDemoBlockMail.style.display = 'block'
            getDemoDetails.style.display = 'flex'

            marketShareResultData = null
            getDemoDetailCountry.innerHTML = '-'
            getDemoDetailKeywords.innerHTML = '-'
          } else if (e.key !== 'ArrowUp' && e.key !== 'ArrowDown') {
            noClearbitData = false

            resultContainer.innerHTML = ''
            resultContainer.insertAdjacentHTML(
              'afterbegin',
              `<button class="get-demo__result letter-domain-logo">
                <div class="custom-domain-logo">${value[0]}</div>
                <span>${value}</span>
                <i>${value}</i>
              </button>`
            )

            data.forEach((item) => {
              const { name, domain } = item
              domain.toLowerCase() == value.toLowerCase() ? document.querySelector('.letter-domain-logo').remove() : ''
              if (domain == value) {
                resultContainer.prepend(
                  resultItem(
                    name,
                    `https://twaigulgqc.execute-api.eu-central-1.amazonaws.com/production/logodo?domain=${domain}&size=32&type=logo`,
                    domain
                  )
                )
              } else {
                resultContainer.appendChild(
                  resultItem(
                    name,
                    `https://twaigulgqc.execute-api.eu-central-1.amazonaws.com/production/logodo?domain=${domain}&size=32&type=logo`,
                    domain
                  )
                )
              }
            })

            if (selectFirstDomain) {
              document.querySelector('.get-demo__result').click()
              getDemoMailInput.focus()

              selectFirstDomain = false
            }
          }
        })
        .then(() => {
          moveViaKeys(e)
        })
        .catch((err) => {
          console.warn('Something went wrong.', err)
        })
    } else {
      resultContainer.innerHTML = ''
      hoveredViaArrowKeys = -1
      isFirstKey = 1
      isArrowUpOrDownPressed = false
    }
  }

  getDemoInput.addEventListener('keyup', getDemoInputResults)
  getDemoInput.addEventListener('keydown', preventJumpingBack)
  heroInputDomains.forEach((p) => p.addEventListener('keyup', getDemoInputResults))

  function updateDomainResultData(domain) {
    if (parse(domain).isIcann) {
      fetch(`https://api-new.seo.do/get_market_share_with_keyword_count`, {
        method: 'POST',
        body: JSON.stringify({
          domain
        })
      })
        .then((response) => {
          return response.json()
        })
        .then((data) => {
          if (data[0]) {
            marketShareResultData = data[0]

            createUserData = getDataFromCode(marketShareResultData.country)

            getDemoDetailCountry.innerHTML = createUserData.country
            getDemoDetailKeywords.innerHTML = numFormatter(marketShareResultData.keyword_count)
          } else {
            marketShareResultData = null
            getDemoDetailCountry.innerHTML = '-'
            getDemoDetailKeywords.innerHTML = '-'
          }
        })
        .catch((err) => {
          console.warn('Something went wrong.', err)
        })
    }
  }

  function getDataFromCode(code) {
    return countries.find((country) => country.code === code)
  }

  demoCta.addEventListener('click', () => {
    getDemo.classList.add('get-demo--active')
  })

  startFreeTrialBtn.forEach((p) => {
    p.addEventListener('click', () => {
      getDemo.classList.add('get-demo--active')
      getDemoInput.focus()
      getDemoDetails.style.display = 'none'
    })
  })

  Array.from(demoDesktopCta).forEach((button) => {
    button.addEventListener('click', () => {
      const val = button.previousElementSibling.previousElementSibling.value
      if (parse(val).isIcann) {
        val.trim().length !== 0 ? (heroCtaButtonFocusCheck = true) : ''
        const domain = getDomain(val)
        wrapperClicked(
          domain,
          `https://twaigulgqc.execute-api.eu-central-1.amazonaws.com/production/logodo?domain=${domain}&size=32&type=logo`,
          domain
        )

        getDemo.classList.add('get-demo--active')
        demoForm.style.display = 'block'
        getDemoStatus.classList.remove('get-demo__status--active')
        getDemoMailInput.value = ''
        if (!heroCtaButtonFocusCheck) {
          getDemoInput.focus()
          getDemoDetails.style.display = 'none'
        } else {
          heroCtaButtonFocusCheck = false
        }

        Array.from(heroInputDomains).forEach((input) => {
          if (input.value.trim().length) {
            if ('createEvent' in document) {
              getDemoInput.value = input.value

              let evt = document.createEvent('HTMLEvents')
              evt.initEvent('keyup', false, true)
              getDemoInput.dispatchEvent(evt)
              selectFirstDomain = true
            } else {
              getDemoInput.fireEvent('onkeyup')
              selectFirstDomain = true
            }
          }
        })
      } else {
        new Notify({
          ...notify_defaults,
          status: 'warning',
          title: notify_messages.domainInvalid
        })
      }
    })
  })

  demoHeaderCta.addEventListener('click', () => {
    getDemo.classList.add('get-demo--active')
  })

  handler.addEventListener(
    'touchmove',
    (e) => {
      if (e.target.offsetTop < e.touches[0].clientY) e.target.style.marginTop = `${e.touches[0].clientY * 1.6 - e.target.offsetTop}px`
    },
    { passive: true }
  )

  handler.addEventListener(
    'touchend',
    () => {
      if (handler.offsetTop > 300) {
        getDemo.classList.remove('get-demo--active')
        handler.removeAttribute('style')
      } else {
        handler.removeAttribute('style')
      }
    },
    { passive: true }
  )
}
