import { hasClass, isMobile } from './helpers'

export default function () {
  const dimensionButtons = document.querySelectorAll('.dimensions__button')
  const dimensionVideoContainers = document.querySelectorAll('.dimensions__video')
  let buttonIndex = 0,
    currDimensionIndex = 0,
    currDimensionID = 'grouping',
    currClicked = false
  let activeDimensionButton = document.querySelector('.dimensions__button--active')

  dimensionButtons.forEach((button, index) => {
    button.addEventListener('click', (e) => {
      !hasClass(e.currentTarget, 'dimensions__button--active') &&
        dimensionTab(e.currentTarget, true, index, true)
    })
    hasClass(button, 'dimensions__button--active') &&
      dimensionTab(button, false, buttonIndex, false)
  })

  const dimensionHeader = document.querySelector('.dimensions__header')
  const dimensionVideos = document.querySelectorAll('.dimensions__header__video video')

  const dimensionObserver = new IntersectionObserver(
    (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          dimensionVideos.forEach((p) => p.play())
        } else {
          dimensionVideos.forEach((p) => p.pause())
        }
      })
    },
    {
      threshold: 0
    }
  )

  dimensionObserver.observe(dimensionHeader)

  ScrollTrigger.create({
    trigger: '.dimensions',
    start: 'top bottom',
    end: 'bottom top',
    onToggle: (self) => dimensionTab(activeDimensionButton, currClicked, buttonIndex, self.isActive)
  })

  function dimensionTab(el, clicked, index, isShowing) {
    dimensionButtons.forEach((button) => {
      button.classList.remove('dimensions__button--active')
    })
    dimensionVideoContainers.forEach((video) => {
      video.classList.remove('dimensions__video--active')
      video.querySelector('video').pause()
      video.querySelector('video').currentTime = 0
    })
    currDimensionID = el ? el.getAttribute('data-dimension-id') : currDimensionID
    activeDimensionButton = el
    const tab = document.querySelector(`[data-dimension="${currDimensionID}"]`)
    buttonIndex = index
    currClicked = clicked
    tab.classList.add('dimensions__video--active')
    el.classList.add('dimensions__button--active')

    document.querySelector(`[data-dimension="${currDimensionID}"] video`).play()
  }

  if (!isMobile()) {
    /* gsap.from('.dimensions__title', {
      autoAlpha: 0,
      y: -30,
      rotation: 0.01,
      scrollTrigger: {
        trigger: '.dimensions__title'
      }
    })

    gsap.from('.dimensions__header > .dimensions__subtitle', {
      autoAlpha: 0,
      y: 30,
      rotation: 0.01,
      scrollTrigger: {
        trigger: '.dimensions__header > .dimensions__subtitle'
      }
    })

    gsap.from('.dimensions__competitors', {
      autoAlpha: 0,
      scale: 0.6,
      rotation: 0.01,
      scrollTrigger: {
        trigger: '.dimensions__competitors'
      }
    })

    gsap.from('.dimensions__exact', {
      autoAlpha: 0,
      scale: 0.6,
      rotation: 0.01,
      scrollTrigger: {
        trigger: '.dimensions__exact'
      }
    })

    gsap.from('.comp-rect', {
      width: 0,
      rotation: 0.01,
      stagger: 0.1,
      scrollTrigger: {
        trigger: '.dimensions__subtitle',
        start: 'top bottom',
        end: 'end bottom'
      }
    })

    gsap.from('.dimensions__subheader', {
      autoAlpha: 0,
      y: 60,
      rotation: 0.01,
      scrollTrigger: {
        trigger: '.dimensions__subheader',
        start: 'top bottom'
      }
    })

    gsap.from('.dimensions__menu', {
      autoAlpha: 0,
      x: -50,
      rotation: 0.01,
      scrollTrigger: {
        trigger: '.dimensions__menu',
        start: 'top bottom',
        end: 'center bottom'
      }
    }) */
  }
}
