import { isMobile } from './helpers'

export default function () {
  if (!isMobile()) {
    // BLOBS
    const modelsBlobLeft = document.querySelector('.models__bg-left')
    const modelsBlobRight = document.querySelector('.models__bg-right')
    const modelsSection = document.querySelector('.models')

    const modelsBlobLeftWarp = new Warp(modelsBlobLeft)
    const modelsBlobRightWarp = new Warp(modelsBlobRight)

    let modelsRightRAF, modelsLeftRAF

    modelsBlobLeftWarp.extrapolate(20)
    modelsBlobRightWarp.extrapolate(20)

    modelsBlobLeftWarp.transform(([x, y]) => [x, y, y])
    modelsBlobRightWarp.transform(([x, y]) => [x, y, y])

    let modelsBlobLeftOffset = 0,
      modelsBlobRightOffset = 0

    function modelsBlobLeftAnimate() {
      modelsBlobLeftWarp.transform(([x, y, oy]) => [
        x,
        oy + 8 * Math.sin(x / 4 + modelsBlobLeftOffset),
        oy
      ])
      modelsBlobLeftOffset += 0.035
      modelsLeftRAF = requestAnimationFrame(modelsBlobLeftAnimate)
    }

    function modelsBlobRightAnimate() {
      modelsBlobRightWarp.transform(([x, y, oy]) => [
        x,
        oy + 8 * Math.sin(x / 8 + modelsBlobRightOffset),
        oy
      ])
      modelsBlobRightOffset += 0.05
      modelsRightRAF = requestAnimationFrame(modelsBlobRightAnimate)
    }

    const modelsLeftObserver = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            modelsLeftRAF = requestAnimationFrame(modelsBlobLeftAnimate)
          } else {
            cancelAnimationFrame(modelsLeftRAF)
          }
        })
      },
      {
        threshold: 0
      }
    )

    const modelsRightObserver = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            modelsRightRAF = requestAnimationFrame(modelsBlobRightAnimate)
          } else {
            cancelAnimationFrame(modelsRightRAF)
          }
        })
      },
      {
        threshold: 0
      }
    )

    modelsLeftObserver.observe(modelsSection)
    modelsRightObserver.observe(modelsSection)

    // SCROLLTRIGGER
    /* gsap.from('.models__bg', {
      y: 100,
      rotation: 0.01,
      scrollTrigger: {
        trigger: '.models__bg',
        start: 'top bottom',
        end: 'center bottom'
      }
    })

    gsap.from('.models__title', {
      autoAlpha: 0,
      y: -30,
      rotation: 0.01,
      scrollTrigger: {
        trigger: '.models__title'
      }
    })

    gsap.from('.models__subtitle', {
      autoAlpha: 0,
      y: 30,
      rotation: 0.01,
      scrollTrigger: {
        trigger: '.models__subtitle'
      }
    })

    gsap.from('.models__card', {
      y: 40,
      rotation: 0.01,
      stagger: 0.2,
      scale: 0.8,
      scrollTrigger: {
        trigger: '.models__card',
        start: 'top bottom',
        end: 'center bottom'
      }
    })

    gsap.from('.models__card > *', {
      y: 100,
      rotation: 0.01,
      autoAlpha: 0,
      stagger: 0.2,
      scrollTrigger: {
        trigger: '.models__card',
        start: 'top bottom',
        end: 'center bottom'
      }
    }) */

    const animateValue = (el, start, end, duration) => {
      let range = end - start
      let current = start
      let increment = end > start ? 1 : -1
      let stepTime = Math.abs(Math.floor(duration / range))
      let obj = el
      let timer = setInterval(() => {
        current += increment
        obj.innerHTML = current
        if (current == end) {
          clearInterval(timer)
        }
      }, stepTime)
    }

    ScrollTrigger.create({
      trigger: '.models__cards',
      onEnter: () => {
        const uiNumbers = document.querySelectorAll('.ui-count')

        uiNumbers.forEach((num) => {
          const max = parseInt(num.textContent)
          const min = parseInt(num.getAttribute('min'))
          animateValue(num, min, max, 2000)
        })
      },
      onLeaveBack: (self) => self.disable()
    })
  }
}
