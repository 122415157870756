import { isMobile, hasClass } from './helpers'

export default function () {
  if (!isMobile()) {
    // BLOBS
    const paradigmBlob = document.querySelector('.paradigm__blob')
    const paradigmSection = document.querySelector('.paradigm')

    const paradigmBlobWarp = new Warp(paradigmBlob)
    let paradigmRAF
    paradigmBlobWarp.extrapolate(40)
    paradigmBlobWarp.transform(([x, y]) => [x, y, y])

    let paradigmBlobOffset = 0

    function paradigmBlobAnimate() {
      paradigmBlobWarp.transform(([x, y, oy]) => [
        x,
        oy + 8 * Math.sin(x / 100 + paradigmBlobOffset),
        oy
      ])
      paradigmBlobOffset += 0.05
      paradigmRAF = requestAnimationFrame(paradigmBlobAnimate)
    }

    const paradigmObserver = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            paradigmRAF = requestAnimationFrame(paradigmBlobAnimate)
          } else {
            cancelAnimationFrame(paradigmRAF)
          }
        })
      },
      {
        threshold: 0
      }
    )

    paradigmObserver.observe(paradigmSection)

    // SCROLLTRIGGER
    /* gsap.from('.paradigm__title', {
      autoAlpha: 0,
      y: 30,
      rotation: 0.01,
      scrollTrigger: {
        trigger: '.paradigm__title',
        start: 'top bottom'
      }
    })

    gsap.from('.paradigm__subtitle', {
      autoAlpha: 0,
      y: 40,
      rotation: 0.01,
      scrollTrigger: {
        trigger: '.paradigm__subtitle',
        start: 'top bottom'
      }
    })

    gsap.from('.paradigm__subheader', {
      autoAlpha: 0,
      y: -40,
      rotation: 0.01,
      scrollTrigger: {
        trigger: '.paradigm__subheader',
        start: 'top bottom'
      }
    })

    gsap.from('.paradigm__bg', {
      scale: 1.4,
      y: 100,
      rotation: 0.01,
      scrollTrigger: {
        trigger: '.paradigm__bg',
        start: 'top bottom'
      }
    })

    gsap.from('.paradigm__box--first', {
      x: -160,
      rotation: 0.01,
      autoAlpha: 0,
      scrollTrigger: {
        trigger: '.paradigm__box--first',
        start: 'top bottom',
        end: 'center bottom-=10%'
      }
    })

    gsap.from('.paradigm__box--third', {
      x: -160,
      rotation: 0.01,
      autoAlpha: 0,
      scrollTrigger: {
        trigger: '.paradigm__box--third',
        start: 'top bottom',
        end: 'center bottom-=10%'
      }
    })

    gsap.from('.paradigm__box--second', {
      x: 160,
      rotation: 0.01,
      autoAlpha: 0,
      scrollTrigger: {
        trigger: '.paradigm__box--second',
        start: 'top bottom',
        end: 'center bottom-=10%'
      }
    })

    gsap.from('.paradigm__box--fourth', {
      x: 160,
      rotation: 0.01,
      autoAlpha: 0,
      scrollTrigger: {
        trigger: '.paradigm__box--fourth',
        start: 'top bottom',
        end: 'center bottom-=10%'
      }
    }) */
  }

  if (isMobile()) {
    const boxes = document.querySelectorAll('.paradigm__box')
    boxes.forEach((box) => {
      const allNodes = box.querySelectorAll(':scope > *')
      const mobileWrapper = document.createElement('div')

      const closeButton = document.createElement('button')
      closeButton.classList.add('paradigm__close')
      closeButton.innerHTML = `<svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path d="M16.243 6.343l-9.9 9.9a1 1 0 101.415 1.414l9.9-9.9a1 1 0 10-1.415-1.414z" fill="currentColor"></path>
      <path d="M17.657 16.243l-9.9-9.9a1 1 0 10-1.413 1.415l9.9 9.899a1 1 0 101.413-1.414z" fill="currentColor"></path>
    </svg>`
      mobileWrapper.classList.add('paradigm__mobile')
      mobileWrapper.appendChild(closeButton)

      allNodes.forEach((n) => {
        const cloned = n.cloneNode(true)
        mobileWrapper.appendChild(cloned)
      })

      box.querySelector('.paradigm__box-desc').insertAdjacentElement('afterend', mobileWrapper)
      box.addEventListener('click', (e) => {
        if (!hasClass(box, 'paradigm__box--active')) {
          boxes.forEach((b) => b.classList.remove('paradigm__box--active'))
          e.currentTarget.classList.add('paradigm__box--active')
          document.body.style.overflow = 'hidden'
        }
      })
    })

    const closeButtons = document.querySelectorAll('.paradigm__close')
    console.log(closeButtons)
    closeButtons.forEach((close) => {
      close.addEventListener('click', (e) => {
        e.stopPropagation()
        document.body.removeAttribute('style')
        boxes.forEach((b) => b.classList.remove('paradigm__box--active'))
      })
    })
  }
}
