export default [
  {
    fromCount: 0,
    toCount: 100,
    pricePerKeyword: 0.09,
    discountRate: '0%'
  },
  {
    fromCount: 101,
    toCount: 250,
    pricePerKeyword: 0.076,
    discountRate: '-15%'
  },
  {
    fromCount: 251,
    toCount: 500,
    pricePerKeyword: 0.074,
    discountRate: '-18%'
  },
  {
    fromCount: 501,
    toCount: 1000,
    pricePerKeyword: 0.067,
    discountRate: '-25%'
  },
  {
    fromCount: 1001,
    toCount: 2500,
    pricePerKeyword: 0.0628,
    discountRate: '-30%'
  },
  {
    fromCount: 2501,
    toCount: 5000,
    pricePerKeyword: 0.0594,
    discountRate: '-35%'
  },
  {
    fromCount: 5001,
    toCount: 10000,
    pricePerKeyword: 0.0597,
    discountRate: '-35%'
  },
  {
    fromCount: 10000,
    toCount: 25000,
    pricePerKeyword: 0.048,
    discountRate: '-45%'
  },
  {
    fromCount: 25001,
    toCount: 50000,
    pricePerKeyword: 0.046,
    discountRate: '-48%'
  },
  {
    fromCount: 50001,
    toCount: 75000,
    pricePerKeyword: 0.044,
    discountRate: '-52%'
  },
  {
    fromCount: 75001,
    toCount: 100000,
    pricePerKeyword: 0.041,
    discountRate: '-54%'
  },
  {
    fromCount: 100001,
    toCount: 125000,
    pricePerKeyword: 0.0392,
    discountRate: '-56%'
  },
  {
    fromCount: 125001,
    toCount: 150000,
    pricePerKeyword: 0.0373,
    discountRate: '-58%'
  },
  {
    fromCount: 150001,
    toCount: 175000,
    pricePerKeyword: 0.0354,
    discountRate: '-60%'
  },
  {
    fromCount: 175001,
    toCount: 200000,
    pricePerKeyword: 0.034,
    discountRate: '-62%'
  },
  {
    fromCount: 200001,
    toCount: 225000,
    pricePerKeyword: 0.032,
    discountRate: '-64%'
  },
  {
    fromCount: 225001,
    toCount: 250000,
    pricePerKeyword: 0.0308,
    discountRate: '-66%'
  }
]
