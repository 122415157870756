export default [
  {
    code: 'ae',
    country: 'United Arab Emirates',
    language: 'ar'
  },
  {
    code: 'ao',
    country: 'Angola',
    language: 'pt'
  },
  {
    code: 'ar',
    country: 'Argentina',
    language: 'es'
  },
  {
    code: 'at',
    country: 'Austria',
    language: 'de'
  },
  {
    code: 'au',
    country: 'Australia',
    language: 'en'
  },
  {
    code: 'be',
    country: 'Belgium',
    language: 'nl'
  },
  {
    code: 'be',
    country: 'Belgium',
    language: 'fr'
  },
  {
    code: 'be',
    country: 'Belgium',
    language: 'de'
  },
  {
    code: 'bg',
    country: 'Bulgaria',
    language: 'bg'
  },
  {
    code: 'bo',
    country: 'Bolivia',
    language: 'es'
  },
  {
    code: 'br',
    country: 'Brazil',
    language: 'pt'
  },
  {
    code: 'ca',
    country: 'Canada',
    language: 'en'
  },
  {
    code: 'ch',
    country: 'Switzerland',
    language: 'de'
  },
  {
    code: 'ch',
    country: 'Switzerland',
    language: 'fr'
  },
  {
    code: 'cl',
    country: 'Chile',
    language: 'es'
  },
  {
    code: 'co',
    country: 'Colombia',
    language: 'es'
  },
  {
    code: 'cr',
    country: 'Costa Rica',
    language: 'es'
  },
  {
    code: 'cy',
    country: 'Cyprus',
    language: 'el'
  },
  {
    code: 'cz',
    country: 'Czechia',
    language: 'cs'
  },
  {
    code: 'de',
    country: 'Germany',
    language: 'de'
  },
  {
    code: 'dk',
    country: 'Denmark',
    language: 'da'
  },
  {
    code: 'dz',
    country: 'Algeria',
    language: 'ar'
  },
  {
    code: 'ec',
    country: 'Ecuador',
    language: 'es'
  },
  {
    code: 'ee',
    country: 'Estonia',
    language: 'et'
  },
  {
    code: 'eg',
    country: 'Egypt',
    language: 'ar'
  },
  {
    code: 'eg',
    country: 'Egypt',
    language: 'en'
  },
  {
    code: 'es',
    country: 'Spain',
    language: 'es'
  },
  {
    code: 'fi',
    country: 'Finland',
    language: 'fi'
  },
  {
    code: 'fr',
    country: 'France',
    language: 'fr'
  },
  {
    code: 'gb',
    country: 'United Kingdom',
    language: 'en'
  },
  {
    code: 'gr',
    country: 'Greece',
    language: 'el'
  },
  {
    code: 'gt',
    country: 'Guatemala',
    language: 'es'
  },
  {
    code: 'hk',
    country: 'Hong Kong',
    language: 'zh_tw'
  },
  {
    code: 'hr',
    country: 'Croatia',
    language: 'hr'
  },
  {
    code: 'hu',
    country: 'Hungary',
    language: 'hu'
  },
  {
    code: 'id',
    country: 'Indonesia',
    language: 'id'
  },
  {
    code: 'ie',
    country: 'Ireland',
    language: 'en'
  },
  {
    code: 'il',
    country: 'Israel',
    language: 'ar'
  },
  {
    code: 'in',
    country: 'India',
    language: 'en'
  },
  {
    code: 'it',
    country: 'Italy',
    language: 'it'
  },
  {
    code: 'jo',
    country: 'Jordan',
    language: 'ar'
  },
  {
    code: 'jp',
    country: 'Japan',
    language: 'ja'
  },
  {
    code: 'lk',
    country: 'Sri Lanka',
    language: 'en'
  },
  {
    code: 'ma',
    country: 'Morocco',
    language: 'ar'
  },
  {
    code: 'mt',
    country: 'Malta',
    language: 'en'
  },
  {
    code: 'mx',
    country: 'Mexico',
    language: 'es'
  },
  {
    code: 'my',
    country: 'Malaysia',
    language: 'ms'
  },
  {
    code: 'ne',
    country: 'Nigeria',
    language: 'en'
  },
  {
    code: 'ni',
    country: 'Nicaragua',
    language: 'es'
  },
  {
    code: 'nl',
    country: 'Netherlands',
    language: 'nl'
  },
  {
    code: 'no',
    country: 'Norway',
    language: 'no'
  },
  {
    code: 'nz',
    country: 'New Zealand',
    language: 'en'
  },
  {
    code: 'pe',
    country: 'Peru',
    language: 'es'
  },
  {
    code: 'pk',
    country: 'Pakistan',
    language: 'en'
  },
  {
    code: 'pl',
    country: 'Poland',
    language: 'pl'
  },
  {
    code: 'pt',
    country: 'Portugal',
    language: 'pt'
  },
  {
    code: 'py',
    country: 'Paraguay',
    language: 'es'
  },
  {
    code: 'ro',
    country: 'Romania',
    language: 'ro'
  },
  {
    code: 'rs',
    country: 'Serbia',
    language: 'sr'
  },
  {
    code: 'ru',
    country: 'Russia',
    language: 'ru'
  },
  {
    code: 'sa',
    country: 'Saudi Arabia',
    language: 'ar'
  },
  {
    code: 'se',
    country: 'Sweden',
    language: 'sv'
  },
  {
    code: 'sg',
    country: 'Singapore',
    language: 'en'
  },
  {
    code: 'si',
    country: 'Slovenia',
    language: 'sl'
  },
  {
    code: 'sk',
    country: 'Slovakia',
    language: 'sk'
  },
  {
    code: 'sv',
    country: 'El Salvador',
    language: 'es'
  },
  {
    code: 'th',
    country: 'Thailand',
    language: 'th'
  },
  {
    code: 'tn',
    country: 'Tunisia',
    language: 'ar'
  },
  {
    code: 'tr',
    country: 'Turkey',
    language: 'tr'
  },
  {
    code: 'tw',
    country: 'Taiwan',
    language: 'zh_tw'
  },
  {
    code: 'ua',
    country: 'Ukraine',
    language: 'ru'
  },
  {
    code: 'ua',
    country: 'Ukraine',
    language: 'uk'
  },
  {
    code: 'us',
    country: 'United States of America',
    language: 'en'
  },
  {
    code: 'uy',
    country: 'Uruguay',
    language: 'es'
  },
  {
    code: 've',
    country: 'Venezuela',
    language: 'es'
  },
  {
    code: 'vn',
    country: 'Vietnam',
    language: 'vi'
  },
  {
    code: 'za',
    country: 'South Africa',
    language: 'en'
  }
]
