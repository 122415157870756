import { hasClass, isMobile, resizeIndicator } from './helpers'

export default function () {
  const featureIndicator = document.querySelector('.features .toggle__indicator')
  const featureTabButtons = document.querySelectorAll('.features .toggle__button')
  const featureRows = document.querySelectorAll('.features__row')

  resizeIndicator('.features .toggle__indicator', '.features .toggle__button--active')

  function featureIndicatorTimeline(play, left, width) {
    const timeline = gsap.timeline({ paused: play })

    timeline
      .to(featureIndicator, { duration: 0.18, scale: 0.9 })
      .to(featureIndicator, { left, width, duration: 0.3, scale: 1 }, '-=.05')
  }

  function featureTimeline(play, el) {
    const timeline = gsap.timeline({ paused: play })

    const id = el.getAttribute('data-feature-id')
    const feature = `[data-feature-row="${id}"] .feature > *:not(svg):not(.link)`
    const featureSVGPaths = `[data-feature-row="${id}"] .feature > svg > *`
    return timeline
      .from(feature, { duration: 0.3, autoAlpha: 0, x: -50, stagger: 0.1 }, 'feature')
      .from(featureSVGPaths, { duration: 0.3, autoAlpha: 0, y: 35, stagger: 0.05 }, 'feature')
  }

  function featureTab(el, clicked) {
    featureTabButtons.forEach((item) => {
      item.classList.remove('toggle__button--active')
    })
    featureRows.forEach((feature) => {
      feature.classList.remove('features__row--active')
    })

    const id = el.getAttribute('data-feature-id')
    const row = document.querySelector(`[data-feature-row="${id}"]`)

    const width = el.offsetWidth
    const left = el.offsetLeft
    clicked && featureTimeline(false, el)
    featureIndicatorTimeline(false, left, width)

    row.classList.add('features__row--active')
    el.classList.add('toggle__button--active')
  }

  featureTabButtons.forEach((button) => {
    button.addEventListener('click', (e) => {
      !hasClass(e.target, 'toggle__button--active') && featureTab(e.target, true)
    })
    button.classList.contains('toggle__button--active') && featureTab(button, false)
  })

  // SCROLLTRIGGER
  if (!isMobile()) {
    /* gsap.from('.features__title', {
      autoAlpha: 0,
      y: 30,
      rotation: 0.01,
      scrollTrigger: {
        trigger: '.features__title',
        start: 'center bottom',
        end: 'bottom bottom-=50'
      }
    })

    gsap.from('.features__subtitle', {
      autoAlpha: 0,
      y: 30,
      rotation: 0.01,
      scrollTrigger: {
        trigger: '.features__subtitle'
      }
    })

    gsap.from('.toggle--features .toggle__button', {
      autoAlpha: 0,
      y: -40,
      rotation: 0.01,
      stagger: 0.2,
      scrollTrigger: {
        trigger: '.toggle--features .toggle__button'
      }
    })

    gsap.from('.toggle--features .toggle__indicator', {
      autoAlpha: 0,
      scale: 0,
      rotation: 0.01,
      scrollTrigger: {
        trigger: '.toggle--features .toggle__indicator'
      }
    })

    gsap.from('.features__row--active .feature > *:not(.link)', {
      autoAlpha: 0,
      stagger: 1,
      duration: 6,
      y: 50,
      rotation: 0.01,
      scrollTrigger: {
        trigger: '.features__row--active .feature',
        start: 'top bottom',
        end: 'bottom+=5% bottom-=5%'
      }
    })

    gsap.from('.features__row--active .feature > svg > *', {
      autoAlpha: 0,
      stagger: 0.4,
      duration: 3,
      y: 50,
      rotation: 0.01,
      scrollTrigger: {
        trigger: '.features__row--active .feature',
        start: 'top bottom',
        end: 'bottom+=5% bottom-=5%'
      }
    }) */
  }
}
