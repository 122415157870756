import { isMobile } from './helpers'

export default function () {
  if (!isMobile()) {
    // SCROLLTRIGGER
    /* gsap.from('.bring__title', {
      autoAlpha: 0,
      y: 30,
      rotation: 0.01,
      scrollTrigger: {
        trigger: '.bring__title',
        start: 'top bottom'
      }
    })

    gsap.from('.bring__desc', {
      autoAlpha: 0,
      y: 30,
      rotation: 0.01,
      scrollTrigger: {
        trigger: '.bring__desc',
        start: 'top bottom'
      }
    })

    gsap.from('.bring__list', {
      autoAlpha: 0,
      y: 30,
      rotation: 0.01,
      scrollTrigger: {
        trigger: '.bring__list'
      }
    })

    gsap.from('.bring .button', {
      autoAlpha: 0,
      y: 30,
      rotation: 0.01,
      scrollTrigger: {
        trigger: '.bring__list'
      }
    })

    gsap.from('.bring__logos', {
      y: -100,
      rotation: 0.01,
      autoAlpha: 0,
      scrollTrigger: {
        start: 'top bottom',
        trigger: '.bring__logos'
      }
    }) */
  }
}
