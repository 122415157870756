export default function () {
  const links = document.querySelectorAll('.link')

  links.forEach((link) => {
    const icon = link.querySelector('.link__icon')
    icon.innerHTML = `
      <video muted="muted">
        <source src="assets/lottie-videos/link.mp4" type="video/mp4"></source>
      </video>
      `
    const video = icon.querySelector('video')
    link.addEventListener('mouseenter', () => {
      video.pause()
      video.currentTime = 0
      video.play()
    })
  })
}
