import { isMobile } from './helpers'

export default function () {
  if (!isMobile()) {
    const ctaBlobs = document.querySelectorAll('.cta__blob')
    const ctaBG = document.querySelectorAll('.cta__bg')
    const ctaTitle = document.querySelectorAll('.cta__title')
    const ctaSubTitle = document.querySelectorAll('.cta__subtitle')
    const ctaForm = document.querySelectorAll('.cta .hero__form')
    const ctaDetail = document.querySelectorAll('.cta__detail')

    ctaBlobs.forEach((cb, index) => {
      // BLOBS
      const ctaBlobWarp = new Warp(cb)
      let ctaRAF

      ctaBlobWarp.extrapolate(40)
      ctaBlobWarp.transform(([x, y]) => [x, y, y])

      let ctaBlobOffset = 0
      function ctaBlobAnimate() {
        ctaBlobWarp.transform(([x, y, oy]) => [x, oy - 7 * Math.sin(x / 5 + ctaBlobOffset), oy])
        ctaBlobOffset += 0.05
        ctaRAF = requestAnimationFrame(ctaBlobAnimate)
      }

      const ctaObserver = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              ctaRAF = requestAnimationFrame(ctaBlobAnimate)
            } else {
              cancelAnimationFrame(ctaRAF)
            }
          })
        },
        {
          threshold: 0
        }
      )

      ctaObserver.observe(ctaForm[index])

      // SCROLLTRIGGER
      /* gsap.from(ctaBG[index], {
        y: -80,
        rotation: 0.01,
        scrollTrigger: {
          trigger: ctaBG[index],
          start: 'top bottom'
        }
      })

      gsap.from(ctaTitle[index], {
        autoAlpha: 0,
        y: 30,
        rotation: 0.01,
        scrollTrigger: {
          trigger: ctaTitle[index],
          start: 'top bottom'
        }
      })

      gsap.from(ctaSubTitle[index], {
        autoAlpha: 0,
        y: 30,
        rotation: 0.01,
        scrollTrigger: {
          trigger: ctaSubTitle[index],
          start: 'top bottom'
        }
      })

      gsap.from(ctaForm[index], {
        autoAlpha: 0,
        y: 50,
        rotation: 0.01,
        scrollTrigger: {
          trigger: ctaForm[index],
          start: 'top bottom'
        }
      })

      gsap.from(ctaDetail[index], {
        autoAlpha: 0,
        y: -40,
        rotation: 0.01,
        scrollTrigger: {
          trigger: ctaDetail[index],
          start: 'top bottom'
        }
      }) */
    })
  }
}
