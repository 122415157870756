import { isMobile, numFormatter } from './helpers'
import pricingValues from '../data/pricing'

export default function () {
  const priceMargin = 16

  if (!isMobile()) {
    // SLIDER
    const range = document.querySelector('.pricing__range')
    const slider = noUiSlider.create(range, {
      start: pricingValues[1].toCount,
      connect: 'lower',
      range: {
        min: pricingValues[0].toCount,
        ...Object.assign(
          {},
          ...pricingValues.slice(1, pricingValues.length - 1).map((pValue, index) => ({
            [`${(100 / (pricingValues.length - 1)) * (index + 1)}%`]: pValue.toCount
          }))
        ),
        max: pricingValues[pricingValues.length - 1].toCount
      },
      pips: {
        mode: 'range',
        format: wNumb({
          edit: function (value, original) {
            return numFormatter(original)
          }
        })
      }
    })

    const sliderBtn = document.querySelector('.pricing .noUi-handle')
    sliderBtn && sliderBtn.setAttribute('aria-label', 'slider')

    const valueNodes = document.querySelectorAll('[data-value]')
    valueNodes[0].previousSibling.classList.add('is-active')

    slider.on('slide', (val) => {
      const num = parseInt([...val])
      const keywordCount = document.querySelector('.keyword-count')
      const keywordPrice = document.querySelector('.keyword-price')
      const keywordDiscount = document.querySelector('.keyword-discount')

      keywordCount.textContent = new Intl.NumberFormat('en-US').format(num)

      pricingValues.forEach((pValue) => {
        if (num >= pValue.fromCount && num <= pValue.toCount) {
          keywordPrice.textContent = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 0
          }).format(Math.round(num * pValue.pricePerKeyword))
          keywordDiscount.textContent = pValue.discountRate
        }
      })

      valueNodes.forEach((value) => {
        const curVal = parseInt(value.getAttribute('data-value'))

        if (num >= curVal) {
          value.previousSibling.classList.add('is-active')
        } else {
          value.previousSibling.classList.remove('is-active')
        }
      })
    })

    slider.on('change', (val) => {
      pricingValues.forEach((pValue) => {
        if (val < pValue.toCount + priceMargin / 2 && val > pValue.toCount - priceMargin) {
          slider.set(pValue)
        }
      })
    })

    const pricingBlobs = document.querySelectorAll('.pricing__blobs svg')
    const pricingSection = document.querySelector('.pricing')

    pricingBlobs.forEach((pb) => {
      // BLOBS
      const pricingWarp = new Warp(pb)

      pricingWarp.extrapolate(40)
      pricingWarp.transform(([x, y]) => [x, y, y])
      let pricingRAF
      let pricingOffset = 0
      function pricingAnimate() {
        pricingWarp.transform(([x, y, oy]) => [x, oy + 8 * Math.sin(x / 70 + pricingOffset), oy])
        pricingOffset += 0.05
        pricingRAF = requestAnimationFrame(pricingAnimate)
      }

      const pricingObserver = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              pricingRAF = requestAnimationFrame(pricingAnimate)
            } else {
              cancelAnimationFrame(pricingRAF)
            }
          })
        },
        {
          threshold: 0
        }
      )

      pricingObserver.observe(pricingSection)

      /* gsap.from(pb, {
        scale: 0.5,
        rotation: 0.01,
        transformOrigin: 'center center',
        scrollTrigger: {
          trigger: '.pricing__box',
          start: 'top bottom',
          end: 'bottom bottom'
        }
      }) */
    })

    // SCROLLTRIGGER
    /* gsap.from('.pricing__title', {
      autoAlpha: 0,
      y: 30,
      rotation: 0.01,
      scrollTrigger: {
        trigger: '.pricing__title',
        start: 'top bottom'
      }
    })

    gsap.from('.pricing .label', {
      autoAlpha: 0,
      y: 30,
      rotation: 0.01,
      scrollTrigger: {
        trigger: '.pricing .label',
        start: 'top bottom'
      }
    })

    gsap.from('.pricing__desc', {
      autoAlpha: 0,
      y: -40,
      rotation: 0.01,
      scrollTrigger: {
        trigger: '.pricing__desc',
        start: 'top bottom'
      }
    })

    gsap.from('.pricing__numbers', {
      autoAlpha: 0,
      y: -40,
      rotation: 0.01,
      scrollTrigger: {
        trigger: '.pricing__numbers',
        start: 'top bottom'
      }
    })

    gsap.from('.pricing .noUi-value-horizontal', {
      autoAlpha: 0,
      y: 40,
      rotation: 0.01,
      scrollTrigger: {
        trigger: '.pricing .noUi-value-horizontal',
        start: 'top bottom'
      }
    })

    gsap.from('.pricing__range-container', {
      y: -40,
      rotation: 0.01,
      scrollTrigger: {
        trigger: '.pricing__range-container',
        start: 'top bottom'
      }
    })

    gsap.from('.pricing__item', {
      y: -40,
      rotation: 0.01,
      autoAlpha: 0,
      stagger: 0.1,
      scrollTrigger: {
        trigger: '.pricing__items',
        start: 'top bottom',
        end: 'bottom bottom'
      }
    }) */
  }
}
