import { isMobile, hasClass } from './helpers'

export default function () {
  // LANGUAGE DROPDOWN
  const langButton = document.querySelector('.footer__lang-button')
  const langMenu = document.querySelector('.footer__lang')

  langButton.addEventListener('click', () => {
    if (!hasClass(langButton.parentNode, 'footer__lang--active')) {
      langMenu.classList.add('footer__lang--active')
    } else {
      langMenu.classList.remove('footer__lang--active')
    }
  })

  document.addEventListener('click', (e) => {
    if (!e.target.closest('.footer__lang')) {
      langMenu.classList.remove('footer__lang--active')
    }
  })

  if (!isMobile()) {
    // BLOBS
    const footerBlob = document.querySelector('.footer__nav-container svg')
    const footerBlobWarp = new Warp(footerBlob)
    const footerSection = document.querySelector('.footer')

    footerBlobWarp.extrapolate(40)
    footerBlobWarp.transform(([x, y]) => [x, y, y])

    let footerBlobOffset = 0
    let footerRAF

    function footerBlobAnimate() {
      footerBlobWarp.transform(([x, y, oy]) => [x, oy + 8 * Math.sin(x / 100 + footerBlobOffset), oy])
      footerBlobOffset += 0.05
      footerRAF = requestAnimationFrame(footerBlobAnimate)
    }

    const footerObserver = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            footerRAF = requestAnimationFrame(footerBlobAnimate)
          } else {
            cancelAnimationFrame(footerRAF)
          }
        })
      },
      {
        threshold: 0
      }
    )

    footerObserver.observe(footerSection)

    // SCROLLTRIGGER
    /* gsap.from('.footer__navs', {
      y: -40,
      rotation: 0.01,
      autoAlpha: 0,
      stagger: 0.1,
      scrollTrigger: {
        trigger: '.footer__navs',
        start: 'top bottom',
        end: 'bottom bottom'
      }
    })

    gsap.from('.footer__nav-container svg path', {
      scale: 0,
      rotation: 0.01,
      transformOrigin: 'center center',
      stagger: 0.1,
      scrollTrigger: {
        trigger: '.footer__nav-container',
        start: 'top bottom',
        end: 'bottom bottom'
      }
    })

    gsap.from('.footer__content > *', {
      y: 60,
      rotation: 0.01,
      autoAlpha: 0,
      stagger: 0.1,
      scrollTrigger: {
        trigger: '.footer__content',
        start: 'top bottom',
        end: 'bottom bottom'
      }
    }) */
  }
}
