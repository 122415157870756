import { isMobile } from './helpers'
// import mobileNavigation from './nav';
import heroSection from './hero'
import featuresSection from './features'
import companiesSection from './companies'
// import waySection from './way';
import dimensionsSection from './dimensions'
import modelsSection from './models'
import ctaSection from './cta'
import paradigmSection from './paradigm'
import fasterSection from './faster'
import bringSection from './bring'
import rankSection from './rank'
import smartSection from './smart'
import pricingSection from './pricing'
// import hiwSection from './hiw';
import footerSection from './footer'
import linkAnimation from './links'

window.addEventListener('DOMContentLoaded', () => {
  // GSAP DEFAULTS
  ScrollTrigger.defaults({
    scrub: 2,
    start: 'bottom bottom',
    end: 'bottom bottom-=15%'
  })

  gsap.defaults({ duration: 3 })

  isMobile() && document.body.classList.add('isMobile')

  // DISABLE SCROLL
  const ENABLE_HOVER_DELAY = 500
  let timer
  window.addEventListener(
    'scroll',
    () => {
      const bodyClassList = document.body.classList
      clearTimeout(timer)

      if (!bodyClassList.contains('disable-hover')) {
        bodyClassList.add('disable-hover')
      }

      timer = setTimeout(() => {
        bodyClassList.remove('disable-hover')
      }, ENABLE_HOVER_DELAY)
    },
    false
  )

  // MOBILE NAVIGATION
  // mobileNavigation();

  // HERO
  heroSection()

  // FEATURES
  featuresSection()

  // LINKS
  linkAnimation()

  // COMPANIES
  companiesSection()

  // WAY
  // waySection();

  // MODELS
  modelsSection()

  // DIMENSIONS
  dimensionsSection()

  // CTA
  ctaSection()

  // PARADIGM
  paradigmSection()

  // FASTER
  fasterSection()

  // BRING
  bringSection()

  // RANK
  rankSection()

  // SMART
  smartSection()

  // PRICING
  pricingSection()

  // HIW
  // hiwSection();

  // FOOTER
  footerSection()
})
