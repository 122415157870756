import { isMobile } from './helpers'

export default function () {
  if (!isMobile()) {
    // SCROLLTRIGGER
    /* gsap.from('.faster__title', {
      autoAlpha: 0,
      x: -120,
      rotation: 0.01,
      scrollTrigger: {
        trigger: '.faster',
        start: 'top bottom'
      }
    })

    gsap.from('.faster__desc', {
      autoAlpha: 0,
      x: -140,
      rotation: 0.01,
      scrollTrigger: {
        trigger: '.faster',
        start: 'top bottom'
      }
    })

    gsap.from('.faster__bg', {
      width: 0,
      scaleX: 1.6,
      rotation: 0.01,
      scrollTrigger: {
        trigger: '.faster__bg',
        start: 'top bottom',
        end: 'bottom bottom'
      }
    })

    gsap.from('.faster__card', {
      y: 60,
      rotation: 0.01,
      autoAlpha: 0,
      stagger: 0.1,
      scrollTrigger: {
        trigger: '.faster__card'
      }
    }) */
  }
}
