import { isMobile } from './helpers'

export default function () {
  if (!isMobile()) {
    /* gsap.from('.companies__title', {
      autoAlpha: 0,
      y: 50,
      rotation: 0.01,
      scrollTrigger: {
        trigger: '.companies__title',
        start: 'top bottom'
      }
    })

    gsap.from('.companies .link', {
      autoAlpha: 0,
      y: 100,
      rotation: 0.01,
      scrollTrigger: {
        trigger: '.companies',
        start: 'top bottom'
      }
    })

    gsap.from('.companies__logo', {
      autoAlpha: 0,
      y: -50,
      rotation: 0.01,
      scrollTrigger: {
        trigger: '.companies__logos',
        start: 'top bottom'
      }
    }) */
  }
}
